<template>
    <div>
        <a-modal width="912px" v-model="showSchemeModal" :header="null" :footer="null" :closable="false"
                 @cancel="closed">

            <div class="qrcode-container" v-if="detail">

                <div class="scheme-thumb">
                    <img :src="detail.thumb" alt="">
                    <div class="scheme-id">
                        <template v-if="detail.app_id">
                            <span>W</span>
                        </template>
                        <template v-else>
                            <span>B</span>
                        </template>
                        <span>{{ detail.id }}</span>
                    </div>
                </div>
                <div class="right-content">
                    <div class="scheme-title">{{ detail.title }}</div>

                    <div class="scheme-tags">
                        <div class="tags-title">搭配信息</div>
                        <div class="tags">
                            <template v-for="(tag,k) in tags">
                                <div :key="k" v-if="detail[tag.key]" class="tag-item">
                                    - {{ tag.name }} :

                                    <template v-for="(item,ik) in detail[tag.key] ">

                                        <span :key="ik">{{ item.name }}</span>

                                        <template v-if="ik !== (detail[tag.key].length - 1)">、</template>

                                    </template>
                                </div>
                            </template>
                        </div>
                    </div>

                    <div class="qrcode">
                        <img @load=" qrLoad = true " :class="{'load':qrLoad}" :src="qrcodeUrl" alt="">
                        <div class="qrcode-tips">扫码快速分享用户</div>
                    </div>

                    <div class="btns">
                        <button class="btn btn-danger" @click="deleteScheme">删除</button>
                        <button class="btn" @click="gotoEdit">编辑</button>
                    </div>

                </div>
            </div>

        </a-modal>
    </div>
</template>

<script>
import api from '../repo/api';
import auth from "../auth";

export default {
    name: "SchemeQrcode",
    model: {
        prop: 'show',
        event: 'change'
    },
    props: {
        schemeId: {
            type: Number || String,
            default() {
                return 0
            }
        },
        type: {
            type: String,
            default() {
                return 'bang';
            }
        },
        show: Boolean,
    },
    watch: {
        show() {
            if (this.show) return this.getDetail()
            this.showSchemeModal = false
        },
        schemeId() {
            this.detail = null
            this.qrLoad = false
            if (this.show) this.getDetail()
        },
    },
    data() {
        return {
            showSchemeModal: false,
            detail: null,
            qrLoad: false,
            tags: [
                {
                    name: '适宜着装场合',
                    key: 'occasion'
                },
                {
                    name: '适宜风格人群',
                    key: 'styles',
                },
                {
                    name: '搭配话题',
                    key: 'topics'
                },
                {
                    name: '季节',
                    key: 'season',
                },

            ]
        }
    },
    mounted() {
        this.showDetail()
    },
    methods: {
        showDetail() {

            if (!this.show) return this.showSchemeModal = false

            if (this.schemeId) this.getDetail()
        },
        closed() {
            this.$emit('change', false)
        },
        gotoEdit() {
            this.$router.push(`/dps/deck?outfit_id=${this.schemeId}&type=${this.type}`)
        },
        deleteScheme() {
            this.$confirm({
                title: "确定要删除这套搭配吗？",
                onOk: () => {
                    this.$loading.show()

                    api.post('/ast-app/delete-scheme', {
                        id: this.schemeId,
                        type: this.type
                    }, (data) => {

                        this.$loading.hide()

                        this.$message.data(data)

                        if (data.code !== 0) return

                        this.$emit('deleted');
                        this.$emit('change', false)

                    })

                }
            })
        },
        getDetail() {

            if (!this.schemeId) return;

            this.$loading.show()

            if (this.type === 'wechat') {

                api.get('/ast-app/scheme-detail', {id: this.schemeId}, (data) => {

                    this.$loading.hide()

                    if (data.code !== 0) return

                    this.detail = data.data

                    if (this.show) this.showSchemeModal = true
                })

            } else {

                api.getOutfitDetail(this.schemeId, (data) => {

                    this.$loading.hide()

                    if (data.code !== 0) return

                    this.detail = data.data

                    if (this.show) this.showSchemeModal = true

                })
            }
        }
    },
    computed: {
        qrcodeUrl() {
            return api.colUrl(`/scheme/mini-qrcode?app_id=${auth.appId()}&scheme_id=${this.detail.id}&type=${this.type}&_token=${auth.getToken()}`)
        }
    }
}
</script>

<style lang="less" scoped>
.qrcode-container {
    padding: 8px;
    display: flex;
    flex-direction: row;
}

.right-content {
    flex: 1;
}

.scheme-thumb {
    width: 492px;
    height: 492px;
    background: #F5F5F5;
    margin-right: 40px;

    img {
        width: 100%;
    }
}

.scheme-title {
    font-size: 24px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 28px;
    margin-top: 16px;
}

.tags-title {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    margin-top: 40px;
    margin-bottom: 20px;


}

.tags {
    margin-top: 20px;
}

.tag-item {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;

    &:last-child {
        margin-bottom: 0;
    }

}

.qrcode {
    width: 124px;
    margin-top: 20px;
    text-align: center;

    background-image: url("/assets/icons/def-qr.png");
    background-size: 100% auto;
    background-repeat: no-repeat;

    img {
        width: 124px;
        height: 124px;
        opacity: 0;

        &.load {
            opacity: 1;
            background-color: white;
        }
    }
}

.qrcode-tips {
    font-size: 14px;
    font-weight: 500;
    color: #333333;
    line-height: 20px;
    margin-top: 8px;
}

.scheme-thumb {
    position: relative;
}

.scheme-id {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 1px 10px;
    background-color: #333333;
    color: white;
}

.btns {
    margin-top: 34px;

    .btn-danger {
        border: 1px solid #F5606D;
        background-color: transparent;
        color: #F5606D;
        margin-right: 15px;
    }
}
</style>