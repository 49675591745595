<template>
    <div class="outfit-item">
        <div class="image-container" :style="`background-image: url(${outfit.thumb})`">
            <div class="outfit-id">
                B{{ outfit.id }}
            </div>
        </div>
        <div class="outfit-detail">
            <div class="outfit-name">
                <div class="name">{{ outfit.title }}</div>
            </div>
            <template v-if="mode === 'yh' ">
                <div class="outfit-prices">
                    <div class="price">
                        <span class="lowest"><span class="fh">￥</span>{{ outfit.price }}</span>
                    </div>
                    <l-a v-if="outfit.member" class="author" :href="`/m/${outfit.member.account}`">
                        <img width="24" height="24" :src="outfit.member.avatar" alt="">
                        <span class="name">{{ outfit.member.name }}</span>
                    </l-a>
                </div>
            </template>
            <template v-else>
                <div class="outfit-prices">
                    <div class="price">
                        <span class="lowest"><span class="fh">￥</span>{{ outfit.price }}</span>
                    </div>
                    <div class="comm">
                        <span>搭赚</span> <span class="fh">￥</span>
                        <span class="comm-am">{{ outfit.commission }}</span>
                    </div>
                </div>
                <div class="time" v-if="mode !== 'yh' ">
                    提交时间：{{ formatTime(outfit.created_at) }}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import LA from "./LA";

export default {
    name: "OutfitItem",
    components: {LA},
    props: {
        outfit: Object,
        mode: String,
    },
    methods: {
        formatTime(t) {
            let d = (new Date());
            d.setTime(t * 1000)
            return d.format('YYYY-mm-dd')
        }
    }
}
</script>

<style lang="less" scoped>
.outfit-item {
    height: 297px;
}

.outfit-name {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;

    .name {
        flex: 1;
        overflow: hidden;
        white-space: nowrap;
        word-break: keep-all;
    }
}

.outfit-plate {
    width: 16px;
    height: 16px;
    line-height: 16px;
    background-color: #dddddd;
    margin-right: 4px;
}

.image-container {
    width: 200px;
    height: 200px;
    background-color: #F5F5F5;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    margin-bottom: 8px;
}

.outfit-detail {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 24px;

}

.outfit-prices {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 10px;
}

.fh {
    font-size: 12px;
    color: inherit;
}

.lowest {
    color: rgba(227, 29, 26, 1);
    display: inline-block;
    margin-right: 9px;
}

.origin {
    color: rgba(0, 0, 0, 0.4);
    text-decoration: line-through;
}

.comm-am {
    color: rgba(255, 173, 0, 1);
}

.time {
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
}

.author {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;

    img {
        margin-right: 5px;
        border-radius: 24px;
    }

    .name {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
    }
}

.image-container {
    position: relative;
}

.outfit-id {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 1px 10px;
    background-color: #333333;
    color: white;
}

</style>