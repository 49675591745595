<template>
    <div>
        <div class="page-title">搭配列表</div>

        <div class="mg-titles">

            <div class="mg-groups">


            </div>

            <div class="mode">
                <a-radio-group @change="getList" v-model="listStatus" default-value="in-stock" button-style="solid">
                    <a-radio-button class="btn-sort" value="in-stock">
                        上架中
                    </a-radio-button>

                    <a-radio-button class="btn-sort" value="waite">
                        待审核
                    </a-radio-button>

                    <a-radio-button class="btn-sort" value="fail">
                        被拒绝
                    </a-radio-button>
                </a-radio-group>
            </div>

        </div>

        <div class="mg-contents">

            <div v-if="loading" class="loading-block">
                <loader/>
            </div>
            <div class="empty" v-else-if="!list.length">
                <a-empty description="暂无任何商品"/>
            </div>
            <div v-else>
                <div class="goods-list">

                    <template v-for="(item,ik) in list">

                        <div class="item cr" :key="ik" @click="showQrcode(item)">
                            <div v-if="item.status === 2" class="reject">未通过</div>
                            <div v-if="item.status === 1 && item.can_search === 1" class="tjz"></div>
                            <outfit-item class="outfit-item" :outfit="item"/>
                        </div>

                    </template>

                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>
                    <div class="goods-item"></div>

                </div>

                <a-affix :offsetBottom="0">
                    <div class="list-footer">
                        <a-pagination
                                class="st-pagination"
                                v-if="pager.total > 0 "
                                :current="pager.current"
                                :total="pager.total"
                                :page-size="pager.size"
                                @change="gotoPage"
                        />

                    </div>
                </a-affix>
            </div>

        </div>

        <scheme-qrcode @deleted="getList" v-model="showQrcodeDetail" :scheme-id="qrcodeDetailId"></scheme-qrcode>

    </div>
</template>

<script>
    import Loader from "../../../components/Loader";
    import api from "../../../repo/api";
    import OutfitItem from "../../../components/OutfitItem";
    import SchemeQrcode from "../../../components/SchemeQrcode";

    export default {
        name: "My",
        components: {SchemeQrcode, OutfitItem, Loader},
        data() {
            return {
                listStatus: "in-stock",
                list: [],
                loading: true,
                query: {
                    page: 1
                },
                pager: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                },
                selectedGoods: [],
                qrcodeDetailId: 0,
                showQrcodeDetail: false
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            showQrcode(item) {
                this.qrcodeDetailId = item.id
                this.showQrcodeDetail = true
            },
            xuan(item) {
                let idx = this.selectedGoods.indexOf(item)

                if (idx >= 0) {

                    this.selectedGoods.splice(idx, 1)

                } else {

                    this.selectedGoods.unshift(item)
                }

            },
            getFirstList() {

            },
            getList() {

                this.query.status = this.listStatus

                api.get('/ast-goods/schemes', this.query, ({data}) => {

                    this.loading = false

                    this.list = data.data
                    this.pager.current = data.current_page
                    this.pager.total = data.total
                    this.pager.size = data.per_page

                })
            },
            gotoPage(page) {
                this.query.page = page
                this.getList()
            },
            fx() {
                let list = []

                this.list.forEach(item => {

                    if (this.selectedGoods.indexOf(item) >= 0) return

                    list.push(item)

                })

                this.selectedGoods = list
            },
            del() {
                let that = this;

                let length = this.selectedGoods.length

                if (length <= 0) return;

                this.$confirm({
                    title: `确定要把这${length}件商品移除自选库？`,
                    content: '此操作不可逆。',
                    onOk() {

                        that.$loading.show()

                        let ids = []

                        that.selectedGoods.forEach(item => ids.push(item.id))

                        api.post('/ast-goods/del', {ids}, (data) => {

                            that.$loading.hide()

                            that.$message.data(data)

                            if (data.code === 0) {
                                that.getList()
                            }

                        })

                    },
                });
            }
        }
    }
</script>

<style lang="less" scoped>

    .mg-titles {
        height: 64px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #EEEEEE;
        padding-left: 25px;
        padding-right: 16px;

        .btn {
            margin-left: 16px;
        }
    }

    .mode .btn-sort {
        margin-right: 0;
        height: 36px;
        line-height: 36px;
    }

    .btn-sort {
        font-size: 14px;
        font-weight: 400;

        border: 1px solid #EEEEEE;
        margin-right: 16px;
        border-radius: 0;
        padding-left: 18px;
        padding-right: 18px;
        height: 32px;
        line-height: 32px;

        /deep/ span {
            color: #999999;
        }

        &:before {
            display: none;
        }

        &.ant-radio-button-wrapper-checked {
            background: #333333;
            border: 1px solid #333333;

            /deep/ span {
                color: #FFFFFF;
            }

            &:hover {
                background-color: #333333;
                border-color: #333333;
            }
        }
    }

    .mg-contents {
        border: 1px solid #EEEEEE;
        border-top: 0;
    }


    .btn-white {

        border: 1px solid #EEEEEE;
        color: #999999;
    }

    .btn-more {
        padding-right: 34px;
        background-image: url("/assets/icons/zx-btn-more.png");
        background-size: 12px;
        background-repeat: no-repeat;
        background-position: 50px 10px;
    }

    .btn-new {
        background-image: url("/assets/icons/zx-btn-new.png");
        background-size: 16px;
        background-repeat: no-repeat;
        padding-left: 32px;
        background-position: 8px;
    }

    .list-footer {
        padding: 0 44px 24px;

        display: flex;
        justify-content: space-between;

        .btn-white {
            margin-right: 16px;
        }
    }

    .item {
        width: 200px;
        margin-right: 40px;
        margin-bottom: 40px;
        position: relative;
    }

    .goods-list {
        padding: 24px 44px;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: -42px;
    }

    .btn-da {
        width: 24px;
        height: 24px;
        background: #333333;
        font-size: 14px;
        text-align: center;
        font-weight: 500;
        color: #ffffff;
        line-height: 24px;
        position: absolute;
        right: 10px;
        top: 10px;
        border: 0;
        border-radius: 50%;
        cursor: pointer;

        &.selected {
            color: #FFFFFF;
            background: #FFAD00;
        }
    }

    .outfit-item {
        cursor: pointer;
    }

    .empty {
        padding: 32px 0 32px 0;
    }
</style>